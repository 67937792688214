<template lang="pug">
    v-dialog(ref="dialog" v-model="modal" :return-value.sync="dates" persistent width='300px' overlay-opacity='0.9')
        template(v-slot:activator='{ on }')
            v-text-field(v-model="dateRangeText" prepend-icon="event" readonly v-on='on')
        v-card.pa-3
            v-radio-group(v-model='quickPick' v-if='quickPick !== "0"')
                v-radio(v-for='(item, index) in quickItems' :key='index' :label='`${item.label}`' :value='item.value' :disabled='item.disable' :ripple='false')
            v-date-picker.month-range.elevation-0(v-model="dates" range no-title :min="minMonth" type="month" full-width v-if='quickPick === "0"' :allowed-dates='allowedDates' :locale='language')
                v-spacer
            v-row
                v-col.d-flex.justify-space-between
                    v-btn.date-btn.mr-3(color="grey darken-2" @click="resetDateRange" large) {{$t('common.cancel')}}
                    v-btn.date-btn.defult-btn-color(v-if='quickPick === "0"' color="primary" @click="saveDates(dates, 0)" :disabled='onOK' large) {{$t('common.ok')}}
</template>
<script>
import { mapActions } from 'vuex';
export default {
	props: ['tab'],
	data() {
		return {
			dates: [this.$moment().subtract(3, 'months').format('YYYY-MM'), 
				this.$moment().subtract(1, 'months').format('YYYY-MM')],
			onOK: false,
			quickPick: '1',
			quickItems: [{
				label: this.$t('date.previous3Months'),
				value: '1',
				disable: false
			},
			// {
			// 	label: this.$t('date.yearToMonth'),
			// 	value: '2',
			// 	disable: false
			// },{
			// 	label: this.$t('date.previousYearToMonth'),
			// 	value: '3',
			// 	disable: false
			// },
			{
				label: this.$t('date.custom'),
				value: '0',
				disable: false
			}],
			language: 'en',
			modal: false,
            
		};
	},
	mounted(){        
		this.setOpenAutoSearch(false);
		this.$emit('emitUpdateMonth',this.dates);
		this.language = localStorage.getItem('language');
		// this.handleMonth();
	},
	computed: {
		dateRangeText() {
			return this.dates.join(' ~ ');
		},
		minMonth(){
			return this.$moment().subtract(18, 'months').format('YYYY-MM');
		}
	},
	watch: {
		tab:function(val){
			let vm = this;
			if(val === 1){
				vm.$emit('emitUpdateMonth',vm.dates);
			}
		},
		dates: function(value){
			if(value[1]){
				this.onOK = false;
			}else{
				this.onOK = true;
			}
			if(value[0]>value[1]){
				const tmp = value[1];
				value[1] = value[0];
				value[0] = tmp;
			}
		},
		quickPick: function(val){
			if(val === '1'){
				this.dates=[this.$moment().subtract(3, 'months').format('YYYY-MM'),
					this.$moment().subtract(1, 'months').format('YYYY-MM')];
				this.saveDates(this.dates);
			}
			//  else if(val === '2'){
			// 	this.dates=[this.$moment().subtract(this.$moment().month(), 'months').format('YYYY-MM'),
			// 		this.$moment().subtract(1, 'months').format('YYYY-MM')];
			// 	this.saveDates(this.dates);
			// } else if(val === '3'){
			// 	this.dates=[this.$moment().subtract(1, 'year').startOf('year').format('YYYY-MM'),
			// 		this.$moment().subtract(1, 'months').format('YYYY-MM')];
			// 	this.saveDates(this.dates);
			// }
		}
	},
	methods: {
		...mapActions(['setOpenAutoSearch']),
		saveDates(dates){
			this.$refs.dialog.save(dates);
			this.$emit('emitUpdateMonth',dates);
		},
		resetDateRange(){
			this.modal = false;
			if(this.quickPick === '0'){
				this.quickPick = '1';
			}
		},
		allowedDates(val) {
			return val < this.$moment().subtract(0, 'months').format('YYYY-MM');
		},
		handleMonth(){
			if(this.$moment().month() === 0){
				this.quickItems[1].disable = true;
			}
		}
	}
};
</script>
