<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
			v-tab {{ $t("charts.everyMonth") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							MonthlyPicker(:tab='tab', @emitUpdateMonth='updateMonth')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			MainSelectItem_v03(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
				v-card.pa-4.filter-wrap.div-w
					v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
						v-row.py-1
							v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
								span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
							v-col.mr-2.py-1.px-3(cols='auto')
								v-img.researchImg(max-width='25px', :src='imgSrc')
					v-row.justify-center
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.apv_bet).length !== 0')
							v-chart(:option='line_chart.apv_bet', ref='line_chart.apv_bet', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.house_net_win).length !== 0')
							v-chart(:option='line_chart.house_net_win', ref='line_chart.house_net_win', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.house_edge).length !== 0')
							v-chart(:option='line_chart.house_edge', ref='line_chart.house_edge', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.profit_amt).length !== 0')
							v-chart(:option='line_chart.profit_amt', ref='line_chart.profit_amt', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.dpst_amt).length !== 0')
							v-chart(:option='line_chart.dpst_amt', ref='line_chart.dpst_amt', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.revenue_amt).length !== 0')
							v-chart(:option='line_chart.revenue_amt', ref='line_chart.revenue_amt', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.reg_users).length !== 0')
							v-chart(:option='line_chart.reg_users', ref='line_chart.reg_users', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.login_users).length !== 0')
							v-chart(:option='line_chart.login_users', ref='line_chart.login_users', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.gm_users).length !== 0')
							v-chart(:option='line_chart.gm_users', ref='line_chart.gm_users', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart.dpst_users).length !== 0')
							v-chart(:option='line_chart.dpst_users', ref='line_chart.dpst_users', autoresize)
</template>

<script>
import _, { indexOf } from 'lodash';
import i18n from '@/locale';
import ChartsSwitch from '@/components/Charts/ChartsSwitch';
import ECharts from 'vue-echarts';
import statusCode from '@/assets/constant/statusCode';
import chart_option_helper from '@/assets/echarts/chart_option_helper';
import { getMarketDaily, getMarketMonthly } from '@/api/Provider';
import { mapActions, mapGetters } from 'vuex';
import { roundOffFilter, roundDecimal, nFormatter} from '@/util/format';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { exportXlsx } from '@/util/xlsx';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import MonthlyPicker from '@/components/DatePicker/MonthPicker_ocms';
import DataPicker from '@/components/DataPicker/DataPicker';
import ExportBtn from '@/components/ExportBtn';
import MainSelectItem_v03 from '@/components/MainSelectItemOCMS_v03';
import COLORS from '@/theme/color';

export default {
	components: {
		'v-chart': ECharts,
		ChartsSwitch,
		ValidationObserver,
		ValidationProvider,
		DaliyPicker,
		MonthlyPicker,
		DataPicker,
		// NetWinPicker,
		ExportBtn,
		MainSelectItem_v03,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			currencyStatus: true,
			show: false,
			helperShow: false,
			loading: false,
			timeSwitch: 0,
			basic: {
				headers: [
					{ text: '', align: 'left', sortable: false, value: 'name' },
					{ text: '', sortable: false, value: 'value' },
				],
				items: [],
			},
			dates: [],
			data: this.$t('charts.turnover'),
			searchDates: [],
			dateFormat: () => Array.from(['MM/DD', 'YYYY/MM'])[this.timeSwitch],
			dimFormat: () => Array.from(['day', 'month'])[this.timeSwitch],
			dateArray: [],
			value: [],
			domainMap: {},
			currency: '',
			topRank: 0,
			marketList: [],
			marketColor: {},
			line_chart: {
				apv_bet: {},
				house_net_win: {},
				house_edge: {},
				profit_amt: {},
				dpst_amt: {},
				revenue_amt: {},
				reg_users: {},
				login_users: {},
				gm_users: {},
				dpst_users: {},
			},
			itemPerPage: 100,
			tab: null,
			imgSrc: require('@/assets/image/research.png'),
		};
	},
	mounted() {
		this.initData();
		this.matchedIndexPin();
		this.onResize();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		smoothDown() {
			$('html, body').animate(
				{
					scrollTop: $('.searchBtn').offset().top,
				},
				500
			);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] == 'daily') {
					this.tab = 0;
				} else if (operatorDefaultRouter[1] == 'monthly') {
					this.tab = 1;
				}
			}
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;

			try {
				let domain = [];
				for (let i of this.value) {
					if (this.domainMap[i].indexOf('-')) {
						domain.push(this.domainMap[i].split('-')[0]);
					} else {
						domain.push(this.domainMap[i]);
					}
				}

				const callFunc = [getMarketDaily, getMarketMonthly];
				const startDate = [
					this.$moment(this.dates[0]).format('YYYY-MM-DD'),
					this.$moment(this.dates[0]).startOf('month').format('YYYY-MM-DD'),
				];
				const endDate = [
					this.$moment(this.dates[1]).format('YYYY-MM-DD'),
					this.$moment(this.dates[1]).startOf('month').add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD'),
				];

				const reqData = {
					startDate: startDate[this.timeSwitch],
					endDate: endDate[this.timeSwitch],
					domain,
					currency: this.currency,
					marketList: this.marketList
				};

				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await callFunc[this.timeSwitch](reqData);
				if (res.status === statusCode.STATUS_OK) {
					let currentDate = this.$moment(this.dates[0]);
					const lastDate = this.$moment(this.dates[1]);
					while (currentDate.isSameOrBefore(lastDate, this.dimFormat())) {
						this.dateArray.push(currentDate.format(this.dateFormat()));
						currentDate.add(1, this.dimFormat());
					}

					localStorage.setItem('currency', this.currency);
					const result = res.result.res;
					this.market = result.ctx_basic_termly;
					this.market.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
					this.market.sort((a, b) => (a.dim_cy_id > b.dim_cy_id ? 1 : -1));
					this.market.forEach(e_1 => e_1.dim_ptt = this.$moment(e_1.dim_ptt).format(this.dateFormat()));

					const line_chart_define_list = [
						{col_name: ['apv_bet', 'apv_bet'], col_key: 'apv_bet'},
						{col_name: ['house_net_win', 'house_net_win'], col_key: 'house_net_win'},
						{col_name: ['house_edge', 'house_edge'], col_key: 'house_edge', ratio: 2},
						{col_name: ['profit_amt', 'profit_amt'], col_key: 'profit_amt'},
						{col_name: ['dpst_amt', 'dpst_amt'], col_key: 'dpst_amt'},
						{col_name: ['revenue_amt', 'revenue_amt'], col_key: 'revenue_amt'},
						{col_name: ['reg_users', 'avg_reg_users'], col_key: 'reg_users'},
						{col_name: ['login_users', 'avg_login_users'], col_key: 'login_users'},
						{col_name: ['gm_users', 'avg_gm_users'], col_key: 'gm_users'},
						{col_name: ['dpst_users', 'avg_dpst_users'], col_key: 'dpst_users'},
					];

					function on_title(p_column, p_time_switch){
						let prefix_key = '';
						if(p_column.col_name[p_time_switch].includes('ttl_')) prefix_key = 'modifier.prefix_ttl';
						if(p_column.col_name[p_time_switch].includes('avg_')) prefix_key = 'modifier.prefix_avg';
						if(p_column.col_name[p_time_switch].includes('avgpu_')) prefix_key = 'modifier.prefix_avgpu';

						let surfix_key = '';
						if(p_column.col_name[p_time_switch].startsWith('bf_')) surfix_key = 'modifier.promo_bf';
						if(p_column.col_name[p_time_switch].startsWith('af_')) surfix_key = 'modifier.promo_af';
						if(p_column.col_name[p_time_switch].startsWith('df_')) surfix_key = 'modifier.promo_df';

						return i18n.t(prefix_key) + i18n.t(`metric.${p_column.col_key}`) + i18n.t(surfix_key);
					}

					// 共用排名資料，只參考依據 apv_bet 指標排名的結果
					let data = this.gen_line_chart_data(this.market, {col_name: 'apv_bet', top_rank: this.topRank});

					// 固定市場顏色
					const colors = COLORS.gen_hue_colors(data.length);
					for (let i_1 = 0; i_1 < data.length; i_1++) {
						const cny = data[i_1][0].dim_cy_id;
						this.marketColor[cny] = colors[i_1];
					}

					for (let i_1 = 0; i_1 < line_chart_define_list.length; i_1++) {
						const el = line_chart_define_list[i_1];
						this.line_chart[el.col_key] = this.gen_line_chart(data, el, on_title);
					}
				} else {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
				this.smoothDown();
				this.tab = this.timeSwitch;

			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		gen_line_chart(p_data, p_opt, p_on_title) {
			const col_list = p_data.map((e_1, ix_1) => {
				return {col_data: e_1, col_key: p_opt.col_key, col_show: e_1[0].dim_cy_id};
			});

			let line_opt = {
				title: {text: p_on_title(p_opt, this.timeSwitch)},
				legend: {
					top: 'bottom',
					textStyle: { color: '#FFF' },
					icon: 'circle',
					data: col_list.map(e_2 => e_2.col_show),
				},
				xAxis: {data: this.dateArray},
				yAxis: {
					axisLabel: {
						formatter: (num) => {
							return p_opt.ratio ? `${roundDecimal(num * 100, p_opt.ratio)} %` : nFormatter(num, 1);
						},
					},
				},
				dataset: col_list.map(e_2 => {
					return {
						dimensions: ['dim_ptt', e_2.col_key],
						source: e_2.col_data,
					};
				}),
				series: col_list.map((e_2, ix_2) => {
					return {
						type: 'line',
						name: e_2.col_show,
						color: this.marketColor[e_2.col_show],
						tooltip: {valueFormatter: (num) => {
							return p_opt.ratio ? `${roundDecimal(num * 100, p_opt.ratio)} %` : roundOffFilter(num);
						}},
						datasetIndex: ix_2,
					};
				}),
			};
			return chart_option_helper.gen_line_opt(line_opt);
		},
		gen_line_chart_data(data, opt) {
			const col_name = opt.col_name;
			// 統計
			let ret_arr = [];
			let rank = {};
			for (let i_1 = 0; i_1 < data.length; i_1++) {
				const el = data[i_1];
				if (_.isUndefined(rank[el.dim_cy_id]))
					rank[el.dim_cy_id] = 0;
				rank[el.dim_cy_id] += el[col_name];
			}
			// 排序
			const rank_sorted = Object.entries(rank).sort((a, b) => b[1] - a[1]);
			// 取出前 N 名
			const top_rank_sorted = rank_sorted.map(([key]) => key).slice(0, opt.top_rank ? opt.top_rank : rank_sorted.length);

			// 組合出前 N 名資料
			const top_rank_data = data.filter(e_1 => top_rank_sorted.includes(e_1.dim_cy_id));
			let grouped_items = _.groupBy(top_rank_data, 'dim_cy_id');
			for (let i_1 = 0; i_1 < top_rank_sorted.length; i_1++) {
				ret_arr[i_1] = grouped_items[top_rank_sorted[i_1]];
			}

			// 組合出其餘名次
			const other_data = data.filter(e_1 => !top_rank_sorted.includes(e_1.dim_cy_id)).map(el => ({...el, dim_cy_id: 'Other'}));
			let other = Object.values(other_data.reduce((acc, cur) => {
				for (let key in cur) {
					const dim_ptt = cur['dim_ptt'];
					if (!acc.hasOwnProperty(dim_ptt))
						acc[dim_ptt] = {};

					if (!acc[dim_ptt].hasOwnProperty(key)) {
						acc[dim_ptt][key] = cur[key];
					} else if (_.isNumber(acc[dim_ptt][key])) {
						acc[dim_ptt][key] += cur[key];
					}
				}
				return acc;
			}, {})).sort((a, b) => (a.dim_ptt > b.dim_ptt ? -1 : 1));

			if (!_.isEmpty(other)) {
				other = other.map(el => {
					if(_.isUndefined(el.house_edge))
						return el;
					const avg = el.house_edge / (rank_sorted.length - top_rank_sorted.length);
					return {...el, house_edge: avg}
				});
				ret_arr.push(other);
			}
			return ret_arr;
		},
		allClean() {
			this.dateArray = [];
			this.basic.items = [];
			this.getExportResult = {};

			Object.keys(this.line_chart).forEach((key_1) => {
				this.line_chart[key_1] = {};
			});
		},
		updateDomainValue(val, marketList, domainMap, currency, topRank) {
			this.value = val;
			this.marketList = marketList;
			this.domainMap = domainMap;
			this.currency = currency;
			this.topRank = topRank;
		},
		updateDate(val) {
			this.dates = val;
		},
		updateMonth(val) {
			this.dates = val;
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				result.ctx_cvt_all = [result.ctx_cvt_all];
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
	},
	watch: {
		tab: function () {
			this.allClean();
			this.helperShow = false;
			if (this.tab == 0) {
				this.timeSwitch = 0;
				this.setOperatorDefaultRouterTab('daily');
			} else if (this.tab == 1) {
				this.timeSwitch = 1;
				this.setOperatorDefaultRouterTab('monthly');
			}
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let adscrtop = $('.div-w').offset().top;

					if (scrollVal + 44 > adscrtop) {
						$('.fixBar').addClass('dateFixBar');
					} else {
						$('.fixBar').removeClass('dateFixBar');
					}
				});
			});
		},
		getExportResult: function () {
			if (Object.keys(this.getExportResult).length !== 0) {
				this.helperShow = false;
			}
		},
	},
	computed: {
		...mapGetters(['getExchangeRate', 'getDialogShow', 'getStatusDialog', 'getOperatorDefaultRouter', 'getOpenAutoSearch']),
	},
	created() {
		this.allClean();
	},
};
</script>
